<i18n>
{
  "en": {
    "browser_title": "Sign Up",
    "signup": "Sign Up Information",
    "tab_download_plans": "Download Plans",
    "tab_streaming_plans": "Streaming Plans"
  },
  "ja": {
    "browser_title": "入会案内",
    "signup": "入会のご案内",
    "tab_download_plans": "ダウンロードプラン",
    "tab_streaming_plans": "ストリーミングプラン"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="joinpage" class="page-margin">
      <div class="block-header">
        <div class="container">
          <h1 @click="getClientID()">{{ $t('signup') }}</h1>
          <!-- hidden UI interaction to expose GA Client ID without poking into debug (for sales) -->
          <div v-if="CID.isVisible">{{ CID.val }}</div>
        </div>
      </div>

      <!-- banner -->
      <join-image />

      <!-- new experience: only show streaming-only packages -->
      <div class="container" v-if="newXPEnabled">
        <div class="section">
          <join-table currency="USD" package-type="join-streaming" />
        </div>
      </div>

      <!-- classic experience: show download and streaming packages -->
      <div class="container" v-else>
        <!-- plan tables (desktop): download tab appears first -->
        <div class="join-plan" v-if="deviceType === 'desktop'">
          <vue-tabs type="pills">
            <v-tab :title="$t('tab_download_plans')">
              <join-table currency="USD" package-type="join" />
            </v-tab>
            <v-tab :title="$t('tab_streaming_plans')">
              <join-table currency="USD" package-type="join-streaming" />
            </v-tab>
          </vue-tabs>
        </div>

        <!-- plan tables (mobile): streaming tab appears first -->
        <div class="join-plan" v-else>
          <vue-tabs type="pills">
            <v-tab :title="$t('tab_streaming_plans')">
              <join-table currency="USD" package-type="join-streaming" />
            </v-tab>
            <v-tab :title="$t('tab_download_plans')">
              <join-table currency="USD" package-type="join" />
            </v-tab>
          </vue-tabs>
        </div>
      </div>

      <div class="container">
        <div class="section">
          <div class="wysiwyg">
            <div v-html="jsonHtml[locale]"></div>
          </div>
        </div>
      </div>
    </div><!-- /#joinpage -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

import UAParser from 'ua-parser-js';
import vueHeadful from 'vue-headful';
import { VueTabs, VTab } from 'vue-nav-tabs';
import APP_CONFIG from '@/appConfig';
import JoinImage from '@/components/join/joinImage.vue';
import JoinTable from '@/components/join/joinTable.vue';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'join-image': JoinImage,
    'join-table': JoinTable,
  },
  data() {
    return {
      dynamicTitle: '',
      jsonHtml: {},
      CID: {
        val: null,
        isVisible: false,
        clickCount: 0,
      },
      deviceType: 'desktop',
    };
  },
  created() {
    this.refreshHTML();
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // redirect to upgrade if they are a site member
    if (this.isSiteMember) this.$router.push({ name: 'upgrade' });

    // get device type
    const parser = new UAParser();
    const device = parser.getDevice();
    // UAParser returns separate 'mobile' and 'tablet' device types (and a few others). Fixes a
    // bug we had where packages, were not appearing for iPads since those were returning 'tablet'
    // device types here, but the package list only designates 'mobile' types or 'desktop'. I
    // doubt Sales will be requesting specific packages for 'tablet' (unless they want to track
    // those separately) so we're just folding all devices as 'mobile' here:
    this.deviceType = device.type ? 'mobile' : 'desktop';
  },
  methods: {
    async refreshHTML() {
      const bfAPI = new BifrostAPI();
      const templates = await bfAPI.getStaticPage();
      this.jsonHtml = templates.join;
    },
    getClientID() {
      if (this.CID.clickCount === 0) {
        this.$analytics.getClientId().then((clientId) => {
          this.CID.val = clientId;
        });
      }
      if (this.CID.clickCount === 8) this.CID.isVisible = true;
      this.CID.clickCount += 1;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEnabled() {
      return (process.env.VUE_APP_NEW_XP_ENABLED === 'true');
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_join.scss';
</style>
