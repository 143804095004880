<i18n>
{
  "en": {
    "purchase_button": "SIGN UP",
    "show_features": "See Features",
    "hide_features": "Hide Features"
  },
  "ja": {
    "purchase_button": "お申し込み",
    "show_features": "特典を表示",
    "hide_features": "特典を非表示"
  }
}
</i18n>

<template>
  <div class="plan-wrap">

    <div class="plan-section" :class="{ 'is-360': (pkg.duration === 360) }" v-for="pkg in orderedPackages" :key="pkg.package_id">
      <div class="plan-entry">
        <div class="plan-overview">
          <!-- plan name + badge -->
          <div
            class="plan-overview__plan"
            :class="{
                      'is-30': (pkg.duration === 30),
                      'is-90': (pkg.duration === 90),
                      'is-180': (pkg.duration === 180),
                      'is-360': (pkg.duration === 360),
                      'is-monthly': (pkg.duration === 30),
                      'is-annual': (pkg.duration === 360),
                    }"
          >
            <div class="plan-name" @click="secretlyShowPackageIDs()">{{ pkg.tpl.description[locale] }}</div>
            <div class="plan-status" v-if="newXPEnabled">{{ pkg.tpl.note.status[locale] }}</div>

            <!-- badges -->
            <div class="plan-status" v-if="!newXPEnabled && locale === 'ja' && pkg.duration === 90"><img src="/img/pages/join/join-ribbon-jp-vip.svg" alt=""></div>
            <div class="plan-status" v-if="!newXPEnabled && locale === 'ja' && pkg.duration === 180"><img src="/img/pages/join/join-ribbon-jp-svip.svg" alt=""></div>
            <div class="plan-status" v-if="!newXPEnabled && locale === 'ja' && pkg.duration === 360"><img src="/img/pages/join/join-ribbon-jp-annual.svg" alt=""></div>
            <div class="plan-status" v-if="!newXPEnabled && locale === 'en' && pkg.duration === 90"><img src="/img/pages/join/join-ribbon-en-vip.svg" alt=""></div>
            <div class="plan-status" v-if="!newXPEnabled && locale === 'en' && pkg.duration === 180"><img src="/img/pages/join/join-ribbon-en-svip.svg" alt=""></div>
            <div class="plan-status" v-if="!newXPEnabled && locale === 'en' && pkg.duration === 360"><img src="/img/pages/join/join-ribbon-en-annual.svg" alt=""></div>
          </div>

          <!-- secret debug for package id -->
          <div class="plan-banner" v-if="PID.isVisible">
            <span class="plan-banner-content">{{ pkg.package_id }}</span>
          </div>

          <!-- campaign text banner -->
          <div
            class="plan-banner"
            v-if="pkg.tpl.banner_text && pkg.tpl.banner_text[locale]"
          >
            <span class="plan-banner-content">{{ pkg.tpl.banner_text[locale] }}</span>
          </div>

          <!-- original price, or if campaign price is the same as the original price -->
          <div
            class="plan-overview__price"
            v-if="!pkg.tpl.price_original
              || (pkg.tpl.price_original && pkg.tpl.price_original === pkg.tpl.price)"
          >
            <sub>{{ pkg.tpl.currency_symbol.prefix }}</sub>{{ pkg.tpl.price }}<sub>{{ pkg.tpl.currency_symbol.suffix }}</sub>
          </div>
          <!-- if campaign price is different than original, show crossed-out original price and campaign price -->
          <div
            class="plan-overview__price is-discounted"
            v-if="pkg.tpl.price_original && pkg.tpl.price_original !== pkg.tpl.price"
          >
            <!-- regular (crossed out) price -->
            <div class="is-regular-price">
              {{ pkg.tpl.currency_symbol.prefix }}{{ pkg.tpl.price_original }}{{ pkg.tpl.currency_symbol.suffix }}
            </div>
            <!-- discounted price -->
            <sub>{{ pkg.tpl.currency_symbol.prefix }}</sub>{{ pkg.tpl.price }}<sub>{{ pkg.tpl.currency_symbol.suffix }}</sub>
          </div>
          <div class="plan-cta">
            <a :href="getPurchaseUrl(pkg)"
              target="_blank"
              rel="noopener">
              <button
                class="join-button button is-filled is-large is-color-highlight"
                @click="clickTrack(pkg)">
                {{ $t('purchase_button') }}
              </button>
            </a>
          </div>
        </div>
        <transition name="slide">
        <div class="plan-detail" v-if="expandedView[pkg.label] === true">
          <ul>
            <li
              v-for="n in pkg.tpl.features[locale].length"
              :key="n"
              :class="{
                // default feature
                'detail-item': (!pkg.tpl.campaign_features || (pkg.tpl.campaign_features && pkg.tpl.campaign_features[locale][n - 1] === '')),
                // campaign feature
                'plan-detail-block': (pkg.tpl.campaign_features && pkg.tpl.campaign_features[locale][n - 1] !== ''),
              }"
            >
              <!-- default feature -->
              <span
                v-if="!pkg.tpl.campaign_features || (pkg.tpl.campaign_features && pkg.tpl.campaign_features[locale][n - 1] === '')"
                v-html="pkg.tpl.features[locale][n - 1]"
              ></span>

              <!-- campaign feature (show strikout original feature + campaign feature) -->
              <span
                v-if="pkg.tpl.campaign_features && pkg.tpl.campaign_features[locale][n - 1] !== ''"
                class="plan-detail-item is-original"
                v-html="pkg.tpl.features[locale][n - 1]"
              ></span>
              <span
                v-if="pkg.tpl.campaign_features && pkg.tpl.campaign_features[locale][n - 1] !== ''"
                class="plan-detail-item is-highlight"
                v-html="pkg.tpl.campaign_features[locale][n - 1]"
              ></span>
            </li>

            <!-- list of features the package doesn't have -->
            <li class="detail-item is-disabled" v-for="feature in pkg.tpl.disabled_features[locale]" :key="feature.$index">
              <span class="detail-note" v-html="feature"></span>
            </li>
          </ul>
        </div>
        </transition>
        <div class="plan-detail-toggle" @click="toggleDetail(pkg.label)"><span v-if="expandedView[pkg.label]"><i class="iconf-remove is-left"></i>{{ $t('hide_features') }}</span><span v-else><i class="iconf-add is-left"></i>{{ $t('show_features') }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';
import orderBy from 'lodash/orderBy';
import APP_CONFIG from '@/appConfig';
import store from '@/store';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';

// minimum width where the details are always visible (and the toggle not visible)
const DETAILS_MIN_WIDTH = 640;

export default {
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    packageType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      packageList: [],
      signerUrls: {},
      expandedView: {},
      deviceType: 'desktop',

      // show package ID info secretly 🤫
      PID: {
        isVisible: false,
        clickCount: 0,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.setExpandedViews);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setExpandedViews);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEnabled() {
      return (process.env.VUE_APP_NEW_XP_ENABLED === 'true');
    },
    orderedPackages() {
      return orderBy(this.packageList, 'tpl.display_order');
    },
    userType() {
      return this.$store.getters['user/typeStr'];
    },
    userClass() {
      return this.$store.getters['user/class'];
    },
    userTier() {
      return this.$store.getters['user/tier'];
    },
  },
  async created() {
    this.getPackagesAndUrls();
  },
  methods: {
    async getPackagesAndUrls() {
      // get device type
      const parser = new UAParser();
      const device = parser.getDevice();
      // UAParser returns separate 'mobile' and 'tablet' device types (and a few others). Fixes a
      // bug we had where packages, were not appearing for iPads since those were returning 'tablet'
      // device types here, but the package list only designates 'mobile' types or 'desktop'. I
      // doubt Sales will be requesting specific packages for 'tablet' (unless they want to track
      // those separately) so we're just folding all devices as 'mobile' here:
      this.deviceType = device.type ? 'mobile' : 'desktop';

      // fetch join and campaign package lists
      const bfAPI = new BifrostAPI();
      const packageList = await bfAPI.getJoinPackages();
      const campaignPackageList = await bfAPI.getCampaignPackages();

      // walk thru campaign packages and override corresponding package in this.packageList
      if (
        Object.prototype.hasOwnProperty.call(campaignPackageList, this.currency)
      ) {
        for (
          let cpl = 0;
          cpl < campaignPackageList[this.currency].length;
          cpl += 1
        ) {
          for (let pl = 0; pl < packageList[this.currency].length; pl += 1) {
            if (
              packageList[this.currency][pl].label
                === campaignPackageList[this.currency][cpl].label
            ) {
              // save original price
              const originalPrice = packageList[this.currency][pl].tpl.price;

              // override original package with campaign package
              packageList[this.currency][pl] = campaignPackageList[this.currency][cpl];

              // restore original price
              packageList[this.currency][pl].tpl.price_original = originalPrice;
              break;
            }
          }
        }
      }

      // get packages to display for this user/page/locale/etc
      // user_type has now been separated into user_class and user_tier
      // - userClass is either non-member/streaming/download
      // - userTier is either non-member/member/vip/svip/annual
      this.packageList = packageList[this.currency].filter(
        item => item.page_visibility[this.packageType]
          && item.user_class_visibility[this.userClass]
          && item.user_tier_visibility[this.userTier]
          && item.device_visibility[this.deviceType]
          && item.locale_visibility[this.locale],
      );

      // set expanded view states for all packages (for toggling details)
      for (let i = 0; i < this.packageList.length; i += 1) {
        this.$set(this.expandedView, this.packageList[i].label, (window.innerWidth >= DETAILS_MIN_WIDTH));

        if (Object.prototype.hasOwnProperty.call(this.packageList[i].tpl, 'details_onload')) {
          this.$set(this.expandedView, this.packageList[i].label, this.packageList[i].tpl.details_onload);
        }
      }
      this.setExpandedViews();

      // first build signerUrls object using fallback URLs (in case we get nothing from Signer)
      const fallbackUrlsArr = this.packageList.map((item) => {
        const obj = {};
        obj[item.label] = item.fallback_url;
        return obj;
      });
      const fallbackUrls = Object.assign({}, ...fallbackUrlsArr);
      this.signerUrls = fallbackUrls;

      // now get signer urls
      this.$analytics.getClientId().then((clientId) => {
        bfAPI.getSignerURLs(clientId).then((response) => {
          if (response.status === 200) {
            if (Object.prototype.hasOwnProperty.call(response.data.data, 'join')) {
              // overwrite fallback urls with signer urls
              Object.keys(this.signerUrls).forEach((label) => {
                if (Object.prototype.hasOwnProperty.call(response.data.data.join, label)) {
                  this.$set(this.signerUrls, label, response.data.data.join[label]);
                }
              });
              if (Object.prototype.hasOwnProperty.call(response.data.data, 'campaign')) {
                // overwrite signerUrl urls with campaign shooter urls
                Object.keys(response.data.data.campaign).forEach((label) => {
                  this.$set(this.signerUrls, label, response.data.data.campaign[label]);
                });
              }
            }
          }
        });
      });
    },
    clickTrack(pkg) {
      this.$analytics.trackEvent('JoinClick', pkg.ga.action, pkg.ga.label);

      // send custom GA4 event (per: http://redmine.dl/issues/3383)
      const durationNameMap = {
        30: 'Monthly Plan',
        360: 'Annual Plan',
      };
      Analytics.sendCustomEvent('join_click', {
        plan_type: 'Streaming Plan',
        plan_detail: (durationNameMap[pkg.duration]) ? durationNameMap[pkg.duration] : pkg.duration,
      });
    },
    getPurchaseUrl(pkg) {
      let purchaseUrl = this.signerUrls[pkg.label];

      // append language param to the purchase URL
      purchaseUrl += pkg.label.includes('-en')
        ? '&lang=en'
        : '&lang=jp';

      // append s2s_id, utm_* if set
      const s2sID = store.getters['purchaseCodes/s2sID'];
      if (s2sID) purchaseUrl += `&s2s_id=${s2sID}`;
      const UTMSource = store.getters['purchaseCodes/UTMSource'];
      if (UTMSource) purchaseUrl += `&utm_source=${UTMSource}`;
      const UTMMedium = store.getters['purchaseCodes/UTMMedium'];
      if (UTMMedium) purchaseUrl += `&utm_medium=${UTMMedium}`;

      // append affiPromo code if set
      const affiPromo = store.getters['purchaseCodes/affiPromo'];
      if (affiPromo) purchaseUrl += `&affi_promo=${affiPromo}`;

      // append GA4 params
      purchaseUrl += `&measurement_id=${process.env.VUE_APP_GA4_MEASUREMENT_ID}&api_secret=${process.env.VUE_APP_GA4_API_SECRET}`;
      purchaseUrl += `&gtm=${process.env.VUE_APP_GTM_ID}`;

      // add GA4 session ID and session number (r3459)
      const gaSessionInfo = this.getGASessionInfo();
      if (gaSessionInfo) purchaseUrl += `&ga_session_id=${gaSessionInfo[0]}&ga_session_number=${gaSessionInfo[1]}`;

      // override shooter domain if needed
      if (Object.prototype.hasOwnProperty.call(APP_CONFIG.purchase, 'hostnames')) {
        Object.keys(APP_CONFIG.purchase.hostnames).forEach((hostname) => {
          if (window.location.hostname.match(hostname)) {
            purchaseUrl = this.replaceProtocolAndHostname(
              purchaseUrl,
              APP_CONFIG.purchase.hostnames[hostname].protocol,
              APP_CONFIG.purchase.hostnames[hostname].hostname,
            );
          }
        });
      }

      return purchaseUrl;
    },
    toggleDetail(label) {
      this.$set(this.expandedView, label, !this.expandedView[label]);
    },
    setExpandedViews() {
      for (let i = 0; i < this.packageList.length; i += 1) {
        this.$set(this.expandedView, this.packageList[i].label, (window.innerWidth >= DETAILS_MIN_WIDTH));
      }
    },
    secretlyShowPackageIDs() {
      if (this.PID.clickCount > 8) this.PID.isVisible = true;
      this.PID.clickCount += 1;
    },
    replaceProtocolAndHostname(url, protocol, hostname) {
      // Remove protocol and hostname from the URL
      const urlObj = new URL(url);
      urlObj.protocol = protocol;
      urlObj.hostname = hostname;

      // Return the modified URL
      return urlObj.href;
    },
    getGASessionInfo() {
      function removePrefix(str) {
        if (str.startsWith('G-')) {
          return str.slice(2);
        }
        return str;
      }
      function extractNumbers(str) {
        const parts = str.split('.');
        let firstNumber = null;
        let secondNumber = null;

        for (let i = 0; i < parts.length; i += 1) {
          const part = parts[i];
          if (part.length === 10 && /^\d+$/.test(part)) {
            firstNumber = part;
            if (i + 1 < parts.length && /^\d+$/.test(parts[i + 1])) {
              secondNumber = parts[i + 1];
            }
            break;
          }
        }

        return [firstNumber, secondNumber];
      }

      const cookie = Cookies.get(`_ga_${removePrefix(process.env.VUE_APP_GA4_MEASUREMENT_ID)}`);
      if (!cookie) return null;
      return extractNumbers(cookie);
    },
  },
};
</script>

<style lang="scss" scoped>
.opaque {
  opacity: 0.15;
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 400px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
